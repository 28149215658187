import React from 'react';

export type CountryContext = {
  country: string;
  filter?: string[];
};

export const CountryContext = React.createContext<CountryContext>({
  country: '',
});

interface AddressContainerProps {
  country: string;
  filter?: string[];
  children: React.ReactNode;
}

const AddressContainer = ({
  country,
  filter,
  children,
}: AddressContainerProps) => {
  return (
    <CountryContext.Provider value={{ country: country, filter: filter }}>
      {children}
    </CountryContext.Provider>
  );
};

export default AddressContainer;
